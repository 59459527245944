import React from 'react';
import { Layout } from '../../components/common';
import Header from '../../components/theme/Header';
import Footer from '../../components/theme/Footer';
import { FormattedMessage } from 'react-intl';
import SEO from '../../components/common/SEO';
import CarouselCustom from '../../components/common/IndexPage/carousel';
import TitleSection from '../../components/common/TitleSection';
import MainContentHolder from './styles';
import PricesCoral from '../../components/common/Prices/pricesCoral';

function importAll(r) {
  return r.keys().map(r);
}

// const imgArray = importAll(require.context('../../../static/Coral', false, /\.(png|jpe?g|svg)$/));

import img1 from '../../../static/Coral/01.jpg';
import img2 from '../../../static/Coral/02.jpg';
import img3 from '../../../static/Coral/03.jpg';
import img4 from '../../../static/Coral/04.jpg';
import img5 from '../../../static/Coral/05.jpg';
import img6 from '../../../static/Coral/06.jpg';
import img7 from '../../../static/Coral/07.jpg';
import img8 from '../../../static/Coral/08.jpg';
import img9 from '../../../static/Coral/09.jpg';
import img10 from '../../../static/Coral/10.jpg';
import img11 from '../../../static/Coral/11.jpg';
import img12 from '../../../static/Coral/12.jpg';
import img13 from '../../../static/Coral/13.jpg';
import img14 from '../../../static/Coral/14.jpg';
import img15 from '../../../static/Coral/15.jpg';
import img16 from '../../../static/Coral/16.jpg';
import img17 from '../../../static/Coral/17.jpg';
import img18 from '../../../static/Coral/18.jpg';
import img19 from '../../../static/Coral/19.jpg';
import img20 from '../../../static/Coral/20.jpg';
import img21 from '../../../static/Coral/21.jpg';
import img22 from '../../../static/Coral/22.jpg';
import img23 from '../../../static/Coral/23.jpg';
import img24 from '../../../static/Coral/24.jpg';
import img25 from '../../../static/Coral/25.jpg';
import img26 from '../../../static/Coral/26.jpg';
import img27 from '../../../static/Coral/27.jpg';
import img28 from '../../../static/Coral/28.jpg';
import img29 from '../../../static/Coral/29.jpg';
import img30 from '../../../static/Coral/30.jpg';
import img31 from '../../../static/Coral/31.jpg';
import img32 from '../../../static/Coral/32.jpg';
import img33 from '../../../static/Coral/33.jpg';
import img34 from '../../../static/Coral/34.jpg';

let imgArray = [
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  img10,
  img11,
  img12,
  img13,
  img14,
  img15,
  img16,
  img17,
  img18,
  img19,
  img20,
  img21,
  img22,
  img23,
  img24,
  img25,
  img26,
  img27,
  img28,
  img29,
  img30,
  img31,
  img32,
  img33,
  img34
];

const apartmentCoral = () => (
  <Layout>
    <React.Fragment>
      <Header />
      <SEO title="ApartmentCoral" />
      <MainContentHolder className="main-content-holder">
        <TitleSection section="coralHeader" />
        <div className="main-content-holder">
          <CarouselCustom imgArray={imgArray} />
        </div>
        <p className="apartment-description">
          <FormattedMessage id="coralDescription" />
        </p>
        <ul className="apartmentItemList">
          <li>
            <FormattedMessage id="airConditioner" />
          </li>
          <li>
            <FormattedMessage id="washingMachine" />
          </li>
          <li>
            <FormattedMessage id="microwave" />
          </li>
          <li>
            <FormattedMessage id="fridge" />
          </li>
          <li>
            <FormattedMessage id="stove" />
          </li>
          <li>
            <FormattedMessage id="kettle" />
          </li>
          <li>
            <FormattedMessage id="coffemachine" />
          </li>
          <li>
            <FormattedMessage id="hairdryer" />
          </li>
          <li>
            <FormattedMessage id="iron" />
          </li>
          <li>
            <FormattedMessage id="tv" />
          </li>
          <li>
            <FormattedMessage id="wifi" />
          </li>
          <li>
            <FormattedMessage id="freeParkingSpot" />
          </li>
        </ul>

        <h3>
          <FormattedMessage id="pricesCoralText" />
        </h3>
        <p>
          <FormattedMessage id="pricesInformation" />
        </p>
        <div className="table-price-holder">
          <PricesCoral />
        </div>
      </MainContentHolder>
      <Footer />
    </React.Fragment>
  </Layout>
);

export default apartmentCoral;
